/* eslint-disable */
import dynamic from 'next/dynamic';


import GoogleTagManagerNoscript from '@magalu/mixer-modules/build/esm/novisual/GoogleTagManagerNoscript';
import AdsBanner from '@magalu/mixer-modules/build/esm/components/ecommerce/AdsBanner';
import TaglessAdUnit from '@magalu/mixer-modules/build/esm/components/ecommerce/TaglessAdUnit';
import Header from '@magalu/mixer-modules/build/esm/components/ecommerce/Header';
import Hotjar from '@magalu/mixer-modules/build/esm/novisual/Hotjar';
import Heading from '@magalu/mixer-modules/build/esm/components/ecommerce/Heading';
import Row from '@magalu/mixer-modules/build/esm/components/ecommerce/Row';
import QuickClickShowcase from '@magalu/mixer-modules/build/esm/components/ecommerce/QuickClickShowcase';
import Grid from '@magalu/mixer-modules/build/esm/components/ecommerce/Grid';
import AdsShowcase from '@magalu/mixer-modules/build/esm/components/ecommerce/AdsShowcase';
import HotLinks from '@magalu/mixer-modules/build/esm/components/ecommerce/HotLinks';
import LuContent from '@magalu/mixer-modules/build/esm/components/ecommerce/LuContent';
import SeoLinkList from '@magalu/mixer-modules/build/esm/components/ecommerce/SeoLinkList';
import JsonLd from '@magalu/mixer-modules/build/esm/novisual/JsonLd';
import Footer from '@magalu/mixer-modules/build/esm/components/ecommerce/Footer';
import Favicon from '@magalu/mixer-modules/build/esm/novisual/Favicon';
import Preconnect from '@magalu/mixer-modules/build/esm/novisual/Preconnect';
import Preload from '@magalu/mixer-modules/build/esm/novisual/Preload';
import Datadog from '@magalu/mixer-modules/build/esm/novisual/Datadog';
import GoogleTagManagerHead from '@magalu/mixer-modules/build/esm/novisual/GoogleTagManagerHead';
import Stewie from '@magalu/mixer-modules/build/esm/novisual/Stewie';
import AllIn from '@magalu/mixer-modules/build/esm/novisual/AllIn';
import Criteo from '@magalu/mixer-modules/build/esm/novisual/Criteo';
import TikTok from '@magalu/mixer-modules/build/esm/novisual/TikTok';
import SpotifyAdAnalytics from '@magalu/mixer-modules/build/esm/novisual/SpotifyAdAnalytics';
import Radware from '@magalu/mixer-modules/build/esm/novisual/Radware';
import RTBHouse from '@magalu/mixer-modules/build/esm/novisual/RTBHouse';
import Vwo from '@magalu/mixer-modules/build/esm/novisual/Vwo';

const pageModules = {
  GoogleTagManagerNoscript,
  AdsBanner,
  TaglessAdUnit,
  Header,
  Hotjar,
  Heading,
  Row,
  QuickClickShowcase,
  Grid,
  AdsShowcase,
  HotLinks,
  LuContent,
  SeoLinkList,
  JsonLd,
  Footer,
  Favicon,
  Preconnect,
  Preload,
  Datadog,
  GoogleTagManagerHead,
  Stewie,
  AllIn,
  Criteo,
  TikTok,
  SpotifyAdAnalytics,
  Radware,
  RTBHouse,
  Vwo,
  BackToTop: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/BackToTop'),
    { ssr: false }
  ),
  CloseableAdUnit: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/CloseableAdUnit'),
    { ssr: false }
  ),
  ZipCodeBar: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/ZipCodeBar'),
    { ssr: false }
  ),
  ZipCodeInput: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/ZipCodeInput'),
    { ssr: false }
  ),
  CookieSecurity: dynamic(() => import('@magalu/mixer-modules/build/esm/novisual/CookieSecurity'),
    { ssr: false }
  ),
  AdsCarousel: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/AdsCarousel'),
    { ssr: false }
  ),
  ActionCard: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/ActionCard'),
    { ssr: false }
  ),
  Showcase: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/Showcase'),
    { ssr: false }
  ),
  Loader: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/Loader'),
    { ssr: false }
  ),
};

export default pageModules;
